<template>
  <div class="ProductTeaserHistory">
    <v-card class="product-teaser-inner mb-4 pa-4 d-flex" tile>
      <figure class="mr-4">
        <a href="#" @click.prevent="isCarousel = true"
          ><img :src="imageLink"
        /></a>
      </figure>

      <div class="meta">
        <h2>{{ item.name }}</h2>
        <h4>{{ item.price }} Pont</h4>
        <small>{{ item.description }}</small>
        <h4>
          <small class="text-uppercase">Mennyiség</small>: <br /><b>{{
            quantity
          }}</b>
        </h4>
        <h4>
          <small class="text-uppercase">Dátum</small>: <br /><b>{{
            formatDate(date)
          }}</b>
        </h4>
      </div>
    </v-card>
    <v-dialog dark v-model="isCarousel">
      <v-carousel
        :hide-delimiters="isSingle"
        :show-arrows="false"
        style="background-color: #fff"
      >
        <v-carousel-item
          v-for="(item, i) in carouselList"
          :key="i"
          :src="item"
          reverse-transition="fade-transition"
          transition="fade-transition"
          height="auto"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import moment from "moment";
import { IMAGE_BASE } from "../util/constants.js";
const PDF_BASE = IMAGE_BASE;
const DEFAULT_IMAGE_URL = "./assets/default_product_image";
export default {
  name: "ProductTeaserHistory",
  data() {
    return {
      count: 0,
      carouselList: [],
      isCarousel: false,
      isAdded: false,
    };
  },
  mounted() {
    if (this.item.productImages.length > 0) {
      this.item.productImages.forEach((item) => {
        let path = item.dirPath.substring(1);
        let name = item.filename;
        this.carouselList.push(`${IMAGE_BASE}${path}${name}`);
      });
    }
    let cart = this.$store.state.cart.items;
    if (cart.length > 0) {
      this.isAdded = cart.some((cartitem) => cartitem.id === this.item.id);
    }
  },
  props: ["item", "date", "quantity"],
  methods: {
    formatDate(d) {
      return moment.unix(d).format("YY.MM.DD");
    },
  },
  computed: {
    imageLink() {
      if (this.item.productImages.length > 0) {
        let path = this.item.productImages[0].dirPath.substring(1);
        let name = this.item.productImages[0].filename;
        return `${IMAGE_BASE}${path}${name}`;
      }

      return null;
    },
    isSingle() {
      return this.item.productImages.length < 2;
    },
  },
};
</script>
<style scoped>
.product-teaser-inner {
  border-bottom: 3px solid var(--red);
}
figure img {
  width: 130px;
  height: 130px;
  object-fit: cover;
}
h2 {
  font-size: 1rem;
  text-transform: uppercase;
}
h4 {
  font-weight: normal;
  margin-bottom: 10px;
  color: #000;
}
</style>
<style>
.v-window-item .v-image__image--cover {
  background-size: contain !important;
}
</style>
