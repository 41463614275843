<template>
  <div class="ProductView">
    <v-card
      class="ml-4 mr-4"
      max-height="100vh"
      style="padding-bottom: 50vh"
      color="transparent"
    >
      <ProductTeaserHistory
        v-for="item in data"
        :item="item.product"
        :date="item.orderedAt"
        :quantity="item.quantity"
        :key="item.id"
      ></ProductTeaserHistory>
      <v-spacer></v-spacer>
    </v-card>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import ProductTeaserHistory from "../components/ProductTeaserHistory.vue";
export default {
  name: "Products",
  components: {
    ProductTeaserHistory,
  },
  data() {
    return {
      clients: [],
      data: [],
      dialog: false,
      activeItem: null,
      isNewItem: false,
      isEditItem: false,
      keyword: null,
    };
  },
  created() {
    this.getList(null);
  },
  methods: {
    getList(keyword) {
      this.$store
        .dispatch("getCollection", {
          end: `client/orders`,
          payload: null,
        })
        .then((res) => {
          this.data = res;
        });
    },
  },
};
</script>
<style scoped>
.tile-grid {
  display: flex;
  flex-wrap: wrap;
}
.rscope {
  position: relative;
}
.absRight {
  position: absolute;
  right: 0;
}
.tile-grid > * {
  flex: 1 1 40%;
  margin: 1rem 1rem 2rem;
}
.cart-bar {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
  right: 0;
}
</style>
